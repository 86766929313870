import React from "react";
import Layout from "../../other/layout";
import Developpez from "../../components/AC-StaticPages/Developpez/Developpez";
import SEO from "../../other/seo";

export default function developpez() {
  return (
    <Layout>
      <SEO title="Développez" />

      <Developpez />
    </Layout>
  );
}
