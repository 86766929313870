import React from "react";

import styles from "./styles.module.css";
import Button from "../components/Button";

export default function Demarrez() {
  const btnMargin = "10px auto";
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Développez votre entreprise</h1>
        <p>
          Toutes les entreprises - petites ou grandes - recherchent des moyens
          d'assurer leur réussite. Deluxe peut leur venir en aide. Avec nos
          solutions novatrices pour bâtir votre marque et renforcer la fidélité
          de votre clientèle, nous sommes en mesure de vous aider à enrichir vos
          relations avec vos clients et augmenter vos affaires.
        </p>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2 style={{ color: "unset", fontSize: "1.5em" }}>Services Web</h2>
            <p>
              Deluxe offre des services professionnels d'hébergement Web, de
              conception de sites Internet. de marketing par courriel et de
              promotion d'entreprise. La richesse de notre service fait de
              Deluxe le meilleur endroit pour héberger le site Internet de votre
              petite entreprise.
            </p>
            <div className={styles.column} style={{ marginBottom: "0" }}>
              <Button
                text={"Hébergement Web"}
                margin={btnMargin}
                url={"/developpez/services-web/hebergement-web/"}
              />
              <Button
                text={"Services de conception de sites web"}
                margin={btnMargin}
                url={"/developpez/services-web/conception-de-sites-web/"}
              />
              <Button
                text={"Promotion de site Web"}
                margin={btnMargin}
                url={"/developpez/services-web/promotion-de-site-web/"}
              />
            </div>
            <div className={styles.column} style={{ marginBottom: "0" }}>
              <Button
                text={"Solutions pour le cybercommerce"}
                margin={btnMargin}
                url={
                  "/developpez/services-web/solutions-pour-le-cybercommerce/"
                }
              />
              <Button
                text={"Serveurs virtuels infogérés"}
                margin={btnMargin}
                url={"/developpez/services-web/serveurs-virtuels-infogeres/"}
              />
              <Button
                text={"Forfaits courriels"}
                margin={btnMargin}
                url={"/developpez/services-web/forfaits-courriels/"}
              />
            </div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2 style={{ color: "unset", fontSize: "1.5em" }}>
              Imprimés d'affaires
            </h2>
            <p>
              Deluxe peut vous aider à distancer vos concurrents en devenant
              votre source de produits qui aideront votre entreprise à
              prospérer. Avec Deluxe, votre commerce et votre marque se
              distingueront grâce à une gamme complète et exclusive d'articles
              conçus pour faire croître vos affaires.
            </p>
            <div className={styles.column} style={{ marginBottom: "0" }}>
              <Button
                text={"Articles couleur"}
                margin={btnMargin}
                url={"/developpez/articles-couleur/"}
              />
              <Button
                text={"Cartes toutes occasions"}
                margin={btnMargin}
                url={"/developpez/cartes-toutes-occasions/"}
              />
              <Button
                text={"Calendriers"}
                margin={btnMargin}
                url={"/developpez/cartes-de-voeux-et-calendriers/calendriers/"}
              />
              <Button
                text={"Articles promotionnels"}
                margin={btnMargin}
                url={"/developpez/articles-promotionnels/"}
              />
            </div>
            <div className={styles.column} style={{ marginBottom: "0" }}>
              <Button
                text={"Vétements pour entreprise"}
                margin={btnMargin}
                url={"/developpez/vetements/"}
              />
              <Button
                text={"Papeterie"}
                margin={btnMargin}
                url={"/gerez/papeterie/"}
              />
              <Button
                text={"Cartes de voeux"}
                margin={btnMargin}
                url={
                  "/developpez/cartes-de-voeux-et-calendriers/cartes-de-voeux/"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
